import { useCallback, useState, useRef } from 'react';

import icon from './img/icon.png';
import './Debugger.css';

import parseSave from './lib/parser';

const seasons = [ 'Spring', 'Summer', 'Fall', 'Winter' ];

export default function Debugger() {
  const [corrupt, setCorrupt] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [href, setHref] = useState(null);
  const [fileName, setFileName] = useState(null);
  const inputRef = useRef();
  const onReset = useCallback(() => {
    setCorrupt(false);
    setData(null);
    setError(false);
    setFileName(null);
    setHref(null);
  }, []);
  const onJSON = useCallback(json => {
    const {
      Farmer,
      SaveGame,
    } = json;
    if (Farmer) {
      setData(Farmer);
    }
    if (SaveGame) {
      setData(SaveGame.player);
    }
  }, []);
  const onXML = useCallback(async (fileName, buffer) => {
    const isSaveGameInfo = fileName === 'SaveGameInfo';
    const fileStart = isSaveGameInfo ? '<Farmer>' : '<SaveGame>';
    const fileEnd = isSaveGameInfo ? '</Farmer>' : '</SaveGame>';
    const fileEndIndex = buffer.indexOf(fileEnd);
    if(buffer.indexOf(fileStart) < 0) {
      setError('Not a Stardew Valley save.');
    }
    else if(fileEndIndex < 0) {
      setError('Unrecoverable error :(');
    }
    else {
      try {
        const xmlEnd = fileEndIndex + fileEnd.length;
        const xml = buffer.slice(0, xmlEnd);
        const json = await parseSave(xml);
        onJSON(json);
        setCorrupt(buffer.length > xmlEnd);
        setHref(
          window.URL.createObjectURL(
            new Blob([xml], {
              type: 'application/octet-stream',
            })
          )
        );
      }
      catch(err) {
        setError(err.message);
        console.error(err);
      }
    }
  }, [onJSON]);
  const onChange = useCallback(() => {
    const [file] = inputRef.current.files;
    setFileName(file.name);
    const reader = new FileReader();
    reader.readAsText(file);
    reader.addEventListener('load', event => onXML(file.name, event.target.result));
  }, [onXML]);
  return (
    <div className="Debugger">
      <p>
        <img src={icon} alt="Stardew Save Editor app icon" />
      </p>
      {!fileName && (
        <>
          <h1>Save File Debugger</h1>
          <input ref={inputRef} type="file" name="file" onChange={onChange} />
        </>
      )}
      {fileName && (
        <>
          {data && (
            <pre>
              {data.name}{`\n`}
              {data.farmName} Farm{`\n`}
              Day {data.dayOfMonthForSaveGame} of {seasons[data.seasonForSaveGame]}, Year {data.yearForSaveGame}
            </pre>
          )}
          {corrupt && (
            <>
              <h3>
                This file is corrupt!
              </h3>
              <p>
                Fixed version: <a href={href} download={fileName}>download</a>
              </p>
            </>
          )}
          {error && (
            <h3>Error: {error}</h3>
          )}
           {!corrupt && !error && (
            <h3>No issues detected.</h3>
          )}
          <button onClick={onReset}>Reset</button>
        </>
      )}
    </div>
  );
}