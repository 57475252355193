import './App.css';
import icon from './img/icon.png';

export default function App() {
  return (
    <div className="App">
      <p>
        <img src={icon} alt="Stardew Save Editor app icon" />
      </p>
      <h1>Stardew Save Editor</h1>
      <p>
        Available on <a
          href="//apps.apple.com/us/app/stardew-save-editor/id1644199292"
          target="_blank"
          rel="noopener noreferrer">App Store</a> and <a
          href="//play.google.com/store/apps/details?id=com.stardewsaveeditor"
          target="_blank"
          rel="noopener noreferrer">Google Play</a>   
      </p>
    </div>
  );
}