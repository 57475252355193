import fastxml from 'fast-xml-parser';

const parseOptions = {
  attributeNamePrefix: '@_',
  // attrNodeName: '@', //default is 'false'
  textNodeName: '#text',
  ignoreAttributes: false,
  ignoreNameSpace: false,
  allowBooleanAttributes: true,
  parseNodeValue: true,
  parseAttributeValue: true,
  trimValues: true,
  // cdataTagName: "__cdata", //default is 'false'
  cdataPositionChar: '\\c',
  // localeRange: "", //To support non english character in tag/attribute values.
  parseTrueNumberOnly: false,
};

export default function toJSON(buffer) {
  return new Promise((resolve, reject) => {
    try {
      const result = fastxml.parse(buffer, parseOptions);
      resolve(result);
    } catch (err) {
      reject(err);
    }
  });
}
